// extracted by mini-css-extract-plugin
export var conTop = "Minor-module--conTop--3zerx";
export var conCont = "Minor-module--conCont--2Viiy";
export var whatWeD = "Minor-module--whatWeD--3OrkW";
export var consHeader = "Minor-module--consHeader--3zk9t";
export var conHow = "Minor-module--conHow--3ccft";
export var conOuter = "Minor-module--conOuter--1byEv";
export var conWhy = "Minor-module--conWhy--2zLU9";
export var conWhyInn = "Minor-module--conWhyInn--3YQB4";
export var conImg = "Minor-module--conImg--1RQem";
export var conAss = "Minor-module--conAss--2U1UQ";
export var topOpsHeading = "Minor-module--topOpsHeading--1tgeQ";
export var opsCont = "Minor-module--opsCont--ApXJm";
export var opsContInn = "Minor-module--opsContInn--1mXMu";
export var opsContInnBtm = "Minor-module--opsContInnBtm--mRl3j";
export var resCont = "Minor-module--resCont--3MLnH";
export var resContInnBtm = "Minor-module--resContInnBtm--1X9gf";
export var resContInn = "Minor-module--resContInn--2c3D7";
export var donCont = "Minor-module--donCont--3oUpd";
export var donContComp = "Minor-module--donContComp--k8En_";
export var donateInn = "Minor-module--donateInn--1GjNV";
export var donateOut = "Minor-module--donateOut--13KvP";
export var donate = "Minor-module--donate--aIAsU";
export var kingbadu = "Minor-module--kingbadu--1Txen";
export var taxico = "Minor-module--taxico--2Zfyt";
export var icos = "Minor-module--icos--2xmgQ";
export var donatelogos = "Minor-module--donatelogos--3vRhO";
export var paypal = "Minor-module--paypal--S3BzR";
export var playIcon = "Minor-module--playIcon--1ZEmI";
export var playIconInn = "Minor-module--playIconInn--33yQb";
export var playTri = "Minor-module--playTri--PBiwC";
export var mpesa = "Minor-module--mpesa--19ZkJ";
export var crypto = "Minor-module--crypto--2DLO0";
export var cryptologos = "Minor-module--cryptologos--1Lsfj";
export var inputsft = "Minor-module--inputsft--2ugFx";
export var modalOuter = "Minor-module--modalOuter--3hado";
export var modalContent = "Minor-module--modal-content--1lepT";
export var modalHead = "Minor-module--modalHead--wG5dK";
export var modalTitle = "Minor-module--modalTitle--3-jAV";
export var modalBody = "Minor-module--modalBody--myxFG";
export var filCont = "Minor-module--filCont--3DxIv";
export var resRFilter = "Minor-module--resRFilter--VhGLv";
export var filterRLabel = "Minor-module--filterRLabel--3HsCI";
export var headerTop = "Minor-module--headerTop--2sYxb";
export var compContTop = "Minor-module--compContTop--2w3Ss";
export var compContTopInn = "Minor-module--compContTopInn--rNHDD";
export var compContVid = "Minor-module--compContVid--1eGq-";
export var compMid = "Minor-module--compMid--2gYXH";